import React, {Component} from "react";
import Identity from "../Identity";
import NavigationWithData from '../Navigation/Navigation.query';
import NavigationWithDataEN from '../Navigation/Navigation.EN.query';
import './Sidebars.scss';
import {currentLanguageByPath} from "../../../helpers/MainHelper";
import LanguageSwitchWithData from "../../molecules/LanuageSwitch/LanguageSwitch.query";

class SidebarLeft extends Component {
    render() {
        return (
            <aside className={'sidebar sidebar-left'}>
                <div className={'sidebar-inner'}>
                    <Identity />

                    <LanguageSwitchWithData activeItem={this.props.activeMenu} activeNid={this.props.activeNid} />

                    { (currentLanguageByPath() === 'en') ?
                        <NavigationWithDataEN activeMenu={this.props.activeMenu} /> :
                        <NavigationWithData activeMenu={this.props.activeMenu} />
                    }

                </div>
            </aside>
        )
    }
}

export default SidebarLeft;
