import React from "react";
import { graphql, StaticQuery} from "gatsby";
import Navigation from "./Navigation";

const NavigationWithData = (props) => (
    <StaticQuery
        query={graphql`
    {
        drupal {
            entityQuery(
                entityType: MENU_LINK_CONTENT
                filter: {
                    conditions: [
                        {operator: EQUAL, field:"menu_name", value: "main"}
                    ]
                }
                sort: { field: "weight", direction: ASC }
            ) {
                items {
                    id
                    label
                    ... on Drupal_MenuLinkContentMenuLinkContent {
                        link {
                            uri {
                                path
                            }
                            title
                        }
                    }
                }
            }
        }
    }
    `}
        render={data => (
            <Navigation className={'navigation'} activeMenu={props.activeMenu}
                        menuItems={data.drupal.entityQuery.items}/>
        )}
    />
);

export default NavigationWithData;