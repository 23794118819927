import React, {Component} from "react";
import {currentLanguageByPath} from "../../../helpers/MainHelper";
import './LanguageSwitch.scss'
import {Link} from "gatsby";
import {trackCustomEvent} from "gatsby-plugin-google-analytics";

class LanguageSwitch extends Component {
    listAliases() {
        let pathAliases = [];

        this.props.data.drupal.nodes.items.forEach((node) => {
            node.translations.forEach((entity) => {
                let pathPrefix = entity.langcode;
                if (pathPrefix === 'nl') {
                    pathPrefix = '';
                } else {
                    pathPrefix = '/' + pathPrefix;
                }
                const pathAlias = pathPrefix + entity.path.alias.toLowerCase();

                if (pathAlias.startsWith('/nl')) {
                    pathAlias.substr(2);
                }

                let node = {};
                if (entity.entityId in pathAliases) {
                    node = pathAliases[entity.id];
                }
                node[entity.langcode] = pathAlias;
                pathAliases[entity.id] = node;
            })
        });

        return pathAliases;
    }
    getCounterPart(language) {
        if (language === undefined) {
            let language = currentLanguageByPath();
            if (language === 'en') {
                language = 'nl';
            } else {
                language = 'en';
            }
        }

        let link = '';

        if (this.props.activeItem === 'home') {
            link = '/' + language;
            if (link.startsWith('/nl')) {
                link = '/';
            }
        }

        if (
            (this.props.activeItem === 'portfolio' && this.props.activeNid === 43) ||
            (this.props.activeItem === 'contact') ||
            (this.props.activeItem === 'snippets' && this.props.activeNid === 55) ||
            (this.props.activeItem === 'blog' && this.props.activeNid === 45)) {

            link = '/' + language + '/' + this.props.activeItem;
            if (link.startsWith('/nl')) {
                link = link.substr(3);
            }
        }
        else if (link === '') {
            if (this.props.activeNid !== undefined && this.props.activeNid !== null) {
                const aliases = this.listAliases();

                if (aliases.indexOf(this.props.activeNid) !== -1) {
                    link = aliases[this.props.activeNid][language];
                }

                // No link found, so fallback is home.
                if (link === undefined || link === '') {
                    link = '/';
                    if (language === 'en') {
                        link = '/en';
                    }
                }
            }
        }

        return link;
    }

    render() {
        return <div className={'language'}>
              <Link
                  to={this.getCounterPart('en')}
                  className={currentLanguageByPath() === 'en' ? 'active' : ''}
                  onClick={e => {
                      // Lets track that custom click
                      trackCustomEvent({
                          // string - required - The object that was interacted with (e.g.video)
                          category: "Language",
                          // string - required - Type of interaction (e.g. 'play')
                          action: "click",
                          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                          label: 'English',
                      })
                      return true;
                  }}

              >English</Link> / <Link
                to={this.getCounterPart('nl')}
                className={currentLanguageByPath() === 'nl' ? 'active' : ''}
                onClick={e => {
                    // Lets track that custom click
                    trackCustomEvent({
                        // string - required - The object that was interacted with (e.g.video)
                        category: "Language",
                        // string - required - Type of interaction (e.g. 'play')
                        action: "click",
                        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                        label: 'Nederlands',
                    })
                    return true;
                }}

        >Nederlands</Link>
            </div>
        // return <Button link={this.getCounterPart()} label={label} class={'language button-orange'}/>;
    }
}

export default LanguageSwitch;
