import React, {Component} from "react";
import {Link} from "gatsby";
import LogoPCK from "../atoms/logos/LogoPCK";
import LogoWB from "../atoms/logos/LogoWB";

class Identity extends Component {
    render() {
        return <div className={'identity'}>
            <Link to={'/'} className={'logo'}>
                <LogoWB /><br />
                <LogoPCK/>
            </Link><br />
            <span className={'subline'}>Freelance Drupal Developer</span>
        </div>;
    }
}

export default Identity;