import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import './Link.scss'
const Link = ({ children, to, activeClassName, ...other }) => {
    const internal = /^\/(?!\/)/.test(to);

    if (internal) {
        return (
            <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
                {children}
            </GatsbyLink>
        );
    }

    return (
        <a href={to} {...other}>
            {children}
        </a>
    );
};

export default Link;
