import React, {Component} from "react";
import {graphql, StaticQuery} from "gatsby";
import LanguageSwitch from "./LanguageSwitch";

class LanguageSwitchWithData extends Component {
    render() {
        return (
            <StaticQuery query={
                graphql`
                {
                    drupal {
                        nodes: entityQuery(
                            entityType: NODE
                            filter: {
                                conditions: [
                                    { operator: EQUAL, field: "status", value: "1" }
                                    { operator: IN, field: "type", value: ["page", "article", "portfolio", "webform"] }
                                ]
                            },
                            limit: 1000
                        ) {
                            items {
                                id
                                __typename
                                ... on Drupal_Node {
                                    langcode
                                    path {
                                        alias
                                    }
                                    ... on Drupal_EntityTranslatable {
                                        translations: translations {
                                            ... on Drupal_Node {
                                                id
                                                title
                                                langcode
                                                path {
                                                    alias
                                                }
                                            }
                                        } 
                                    }                                    
                                }
                            }
                        }
                    }
                }
                `
            } render={data => (
                <LanguageSwitch data={data} activeItem={this.props.activeItem} activeNid={this.props.activeNid} />
            )} />
        )
    }
}

export default LanguageSwitchWithData;
