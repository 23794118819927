import {graphql, StaticQuery} from "gatsby";
import Navigation from "./Navigation";
import React from "react";

const NavigationWithDataEN = (props) => (
    <StaticQuery
        query={graphql`
    {
        drupal {
            entityQuery(
                entityType: MENU_LINK_CONTENT
                filter: {
                    conditions: [
                        {operator: EQUAL, field:"menu_name", value: "main-english"}
                    ]
                }
                sort: { field: "weight", direction: ASC }
            ) {
                items {
                    id
                    label
                    ... on Drupal_MenuLinkContentMenuLinkContent {
                        id
                        link {
                            uri {
                                path
                            }
                            title
                        }
                    }
                }
            }
        }
    }
    `}
        render={data => (
            <Navigation className={'navigation'} activeMenu={props.activeMenu}
                        menuItems={data.drupal.entityQuery.items}
            />
        )}
    />
);

export default NavigationWithDataEN;
