import React, {Component} from "react";
import Link from "../../atoms/link/link";
import {currentLanguageByPath, ProcessMenuLink} from '../../../helpers/MainHelper';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import './Navigation.scss';
import NavigationBar from "./NavigationBar";

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuActive: false
        };

        this.toggleExtend = this.toggleExtend.bind(this);
    }

    checkActive(currentPath, isLI = false) {
        if (currentPath.startsWith('/nl')) {
            currentPath = currentPath.substr(3);
        }

        if (currentPath === '') {
            currentPath = '/';
        }

        if (typeof window !== 'undefined') {
            if (!(currentPath === '/' || currentPath === '/en') && window.location.pathname.startsWith(currentPath)) {
                if (isLI) {
                    return 'current';
                }
                return ' active';
            }
            else if (currentPath === '/' || currentPath === '/en') {
                if (currentPath === window.location.pathname) {
                    if (isLI) {
                        return 'current';
                    }
                    return ' active';
                }
            }

        }
        return '';
    }

    toggleExtend() {
        let newState = this.state.menuActive !== true
        this.setState({menuActive: newState});
    }


    render() {
        let classNames = 'block main-navigation';
        if (this.state.menuActive === true) {
            classNames += ' extended';
        }

        return (
            <nav className={classNames}>
                <NavigationBar toggleAction={this.toggleExtend} toggleState={this.state.menuActive} />
                <div className={'navigation-extendable'}>
                    <ul className={this.props.className}>
                            {this.props.menuItems.map((menuItem, index) => (
                            <li className={this.checkActive(menuItem.link.uri.path, true)} key={index}>
                                <Link
                                    className={menuItem.class + this.checkActive(menuItem.link.uri.path)}
                                    to={ProcessMenuLink(menuItem.link.uri.path)}
                                    onClick={e => {
                                        // Lets track that custom click
                                        trackCustomEvent({
                                            // string - required - The object that was interacted with (e.g.video)
                                            category: "Navigation",
                                            // string - required - Type of interaction (e.g. 'play')
                                            action: "click",
                                            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                            label: menuItem.title,
                                        })
                                        return true;
                                    }}
                                    >{menuItem.label}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Navigation;
