import React from "react";
import logo_pck from '../../../../src/assets/logo_pck.svg';
import './LogoPCK.scss';

const LogoPCK = () => {
    return (
        <>
        <img className={'logo-pck'} src={logo_pck} alt={'Patrick C. Kannekens'}/>
        </>
    )
};

export default LogoPCK;
