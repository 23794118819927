import React, {Component} from "react";
import './Main.scss';

class Main extends Component {
    render() {
        return (
            <section id={'main'} className={this.props.className}>
                {this.props.children}
            </section>
        )
    }
}

export default Main;