import React from 'react';
import i18next from "i18next";

export function StripTags(text) {
  if (typeof document !== 'undefined') {
    const elem = document.createElement('textarea');
    elem.innerHTML = text;
    const decoded = elem.value;
    return decoded.replace(/(<([^>]+)>)/ig, '');
  }
  return text.replace(/(<([^>]+)>)/ig, '');
}

export function ProcessMenuLink(URL) {
  if (URL !== undefined) {
    let language = currentLanguageByPath();

    URL = URL.replace('internal:', '');

    if (URL.startsWith('/nl')) {
      URL = URL.substr(3);
    }

    if (language !== 'nl' && !URL.startsWith('/' + language)) {
      URL = '/' + language + URL;
    }

    if (!URL.length) {
      URL = '/';
    }
  }

  return URL;
}

export function FormatDate(dateText) {
  if (dateText !== undefined) {
    let objDate = new Date(dateText);
    let month = objDate.getMonth()+1;
    if (month.toString().length === 1) {
      month = '0' + month.toString();
    }

    return month + '-' + objDate.getFullYear();
  }
}

export function MergeTags(...tags) {
  let mergedTags = [];
  tags.forEach((tag) => {
    if (tag) {
      mergedTags = mergedTags.concat(tag);
    }
  });
  return mergedTags;
}

export function ParseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  return query;
}

/**
 *
 * @param metatagNormalized
 * @returns {string|Array}
 * @constructor
 */
export function GetMetatags(metatagNormalized) {
  const metatags = [];

  if (metatagNormalized !== 'undefined' && metatagNormalized) {
    metatagNormalized.forEach((tags) => {
      // const attr = JSON.parse(tags.attributes);
      const attributes = [];
      tags.attributes.forEach((attr) => {
        attributes[attr.key] = attr.value;
      });

      if (tags.tag === 'meta') {
        metatags.push(
          <meta
            key={attributes['name']}
            name={attributes['name']}
            content={attributes['content']}
          />
        );
      }
      else if (tags.tag === 'link') {
        const href = attributes['href'].replace('https://backend.web-beest.nl', 'https://www.web-beest.nl');
        metatags.push(
            <link
                key={attributes['rel']}
                rel={attributes['rel']}
                href={href}
            />
        );
      }
    });

    return metatags;
  }

  return '';
}

export function getTranslations(langcode) {
  const resources = {
    en: {
      translation: {
        'night' : 'Goodnight!',
        'morning': 'Goodmorning!',
        'afternoon': 'Goodafternoon!',
        'evening': 'Goodevening!',
        'back_blog': 'Back to blog overview',
        'back_portfolio': 'Back to portfolio list',
        'back_snippet': 'Back to snippet list',
        'more_information': 'More information',
      },
    },
    nl: {
      translation: {
        'night' : 'Goedenacht!',
        'morning': 'Goedemorgen!',
        'afternoon': 'Goedemiddag!',
        'evening': 'Goedenavond!',
        'back_blog': 'Terug naar het blog overzicht',
        'back_portfolio': 'Terug naar het portfolio overzicht',
        'back_snippet': 'Terug naar het overzicht',
        'more_information': 'Meer informatie',
      }
    }
  }
  i18next.init({
    resources,
    lng: langcode,
  });

  return i18next;
}

export function getGreeting(langcode) {
  const i18next = getTranslations(langcode);
  let title = i18next.t('night');
  let currentDate = new Date();

  if (currentDate.getHours() > 4 && currentDate.getHours() < 12) {
    title = i18next.t("morning");
  }

  if (currentDate.getHours() >= 12 && currentDate.getHours() <= 17) {
    title = i18next.t("afternoon");
  }

  if (currentDate.getHours() > 17 && currentDate.getHours() <= 23) {
    title = i18next.t("evening");
  }

  return title;
}

export function currentLanguageByPath() {
  const languages = {
    'en': 'English',
    'nl': 'Dutch'
  };

  const url = typeof window !== 'undefined' ? window.location.pathname : '';

  if (url !== '') {
    return Object.keys(languages).find(langcode => url.startsWith(`/${langcode}`)) ?? 'nl';
  }
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}