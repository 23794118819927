import React, {Component} from "react";
import {StripTags, GetMetatags} from "../../helpers/MainHelper";
import {Helmet} from "react-helmet/es/Helmet";
import Main from "../organisms/Main/Main";
import SidebarLeft from "../organisms/Sidebars/SidebarLeft";
import SidebarRight from "../organisms/Sidebars/SidebarRight";
import CookieConsent from "react-cookie-consent";

class Layout extends Component {
    render() {
        const metatags = GetMetatags(this.props.metatags);
        const siteName = 'Patrick C. Kannekens - Freelance Drupal Developer';

        return (
            <>
                <Helmet defaultTitle={`${this.props.title ? `${StripTags(this.props.title)} - ` : ''}${siteName}`}>
                    {metatags}
                    <html lang={this.props.lang} />
                    <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
                    <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
                    <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
                    <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
                    <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
                    <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
                    <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
                    <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
                    <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
                    <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                </Helmet>

                <SidebarLeft activeMenu={this.props.activeMenu} activeNid={this.props.activeNid} />
                <Main className={this.props.class}>
                    {this.props.children}
                </Main>
                <SidebarRight />

                <CookieConsent
                    enableDeclineButton
                    location="bottom"
                    buttonText="Ja, geef mij dat cookie"
                    declineButtonText="Nee, bedankt"
                    cookieName="gatsby-gdpr-google-analytics"
                >
                    Ik wil graag weten hoeveel bezoeker ik heb en wat ze doen op mijn site (via Google Analytics). Om dit te doen gebruik ik cookies.
                    De data wordt anoniem opgeslagen en ik respecteer de Do Not Track voorkeuren van uw browser. Wilt u een cookie van mij?
                </CookieConsent>
            </>
        );
    }
}

export default Layout;
