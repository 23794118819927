import React, {Component} from "react";

import IconMail from '../../../../src/assets/icon-mail.svg';
import IconLinkedIn from '../../../../src/assets/icon-linkedin.svg';
import IconDrupal from '../../../../src/assets/icon-drupal.svg';
import Link from "../../atoms/link/link";
import './NavigationBar.scss';

class NavigationBar extends Component {
    render() {
        let classNames = 'hamburger hamburger--squeeze';

        if (this.props.toggleState === true) {
            classNames += ' is-active';
        }
        return (
            <>
                <div className={'navigation-bar'}>
                    <ul className={'socials'}>
                        <li><Link to={'mailto:info@web-beest.nl'}><img src={IconMail} alt={'Mail me'} /></Link></li>
                        <li><Link to={'https://linkedin.com/in/patrickkannekens'}><img src={IconLinkedIn} alt={'Maak connectie op LinkedIn'}/></Link></li>
                        <li><Link to={'https://drupal.org/u/Web-Beest'}><img src={IconDrupal} alt={'Mijn drupal.org profiel'}/></Link></li>
                    </ul>

                    <div className="navigation-extend">
                        <button className={classNames} type="button" aria-label="Menu"
                                aria-controls="navigation" onClick={this.props.toggleAction}>
                          <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                          </span>
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

export default NavigationBar;
