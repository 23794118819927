import React, {Component} from "react";
import IndividualMember from '../../../../src/assets/association_ind_member_badge.svg';
import OrganisationMember from '../../../../src/assets/association_org_member_badge.svg';
import Acquia from '../../../../src/assets/D7CD_1.png';
import { OutboundLink } from "gatsby-plugin-google-analytics"

class SidebarRight extends Component {
    render() {
        return (
            <aside className={'sidebar sidebar-right'}>
                <OutboundLink href={'https://www.drupal.org/u/web-beest'} target={'_blank'} rel={'noreferrer noopener'}><img src={IndividualMember} alt={'Drupal Association: Individual member'} width={81} height={81} /></OutboundLink>
                <OutboundLink href={'https://www.drupal.org/u/web-beest'} target={'_blank'} rel={'noreferrer noopener'}><img src={OrganisationMember} alt={'Drupal Association: Organisation member'} width={81} height={81} /></OutboundLink>
                <OutboundLink href={'https://certification.acquia.com/user/749438'} target={'_blank'} rel={'noreferrer noopener'}><img src={Acquia} alt={'Acquia Certified Drupal Developer'} width={81} height={81} /></OutboundLink>
            </aside>
        )
    }
}

export default SidebarRight;
