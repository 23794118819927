import React from "react";
import logo_wb from '../../../../src/assets/logo_wb.svg';
import './LogoWB.scss';

const LogoWB = () => {
    return (
        <>
        <img className={'logo-wb'} src={logo_wb} alt={'Web-Beest logo'}/>
        </>
    )
};

export default LogoWB;
